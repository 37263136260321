<template>
  <div>
    <Menubar v-if="user.data.verband == template.var.verband" />
    <Zuschauermenubar v-if="user.data.verband != template.var.verband" />
    <v-main class="pa-0">
      <v-container fluid style="overflow-x: hidden; padding-top: 5vh;">
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6">
            <v-fade-transition hide-on-leave>
              <router-view></router-view>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <notifications />
  </div>
</template>

<script>
import Menubar from '../nav/nav'
import Zuschauermenubar from '../zuschauer/nav'
import router from '../../routes'
//import firebase from '../../firebaseConfig'
import { mapGetters } from 'vuex'
import logout from '../../auth/auth'
import store from '../../store'
import notifications from '../benachrichtigungen/notification_handler'

export default {
  name: 'Start',
  data() {
    return {
      notify: false,
    }
  },
  components: {
    Menubar,
    Zuschauermenubar,
    notifications,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      publicdata: 'publicdata',
      timer: 'timer',
    }),
  },
  mounted() {
    if (this.user) {
      if (this.user.data) {
        if (this.user.data.rolle) {
          if (
            this.user.data.rolle == 'Zuschauer' ||
            this.user.data.rolle == 'Schiedsrichter' ||
            (this.user.data.verband != this.template.var.verband &&
              !router.currentRoute.fullPath
                .toString()
                .includes('/veranstaltung/'))
          ) {
            if (this.user.fullfill) {
              router.push('/profil-vervollstaendigen')
            } else {
              router.push('/user/check-ins')
            }
          }
        }
      } else {
        router.push('/login')
      }
    } else {
      router.push('/login')
    }

    this.startTimer()
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        router.push('/login')
      } else {
        if (user.isAnonymous) {
          logout.logout()
          router.push('/login')
        }
        if (this.user) {
          if (this.user.data) {
            if (this.user.data.rolle) {
              if (
                this.user.data.rolle == 'Zuschauer' ||
                this.user.data.rolle == 'Schiedsrichter' ||
                (this.user.data.verband != this.template.var.verband &&
                  !router.currentRoute.fullPath
                    .toString()
                    .includes('/veranstaltung/'))
              ) {
                if (this.user.fullfill) {
                  router.push('/profil-vervollstaendigen')
                } else {
                  router.push('/user/check-ins')
                }
              }
            }
          }
        }
      }
    })
  },
  methods: {
    startTimer() {
      store.dispatch('setTimer')
      setTimeout(() => {
        this.startTimer()
      }, 1000)
    },
  },
}
</script>
