<template>
  <div>
    <v-snackbar
      v-model="value"
      :color="template.colors.primary"
      app
      top
      right
      rounded="xl"
      transition="v-snack-transition"
      :timeout="timeout"
    >
      <v-row class="pa-0">
        <v-col cols="auto" class="py-0" align-self="center">
          <v-avatar class="my-auto" large>
            <v-icon
              :style="`background-color: ${template.colors.inline_primary_text}; color: ${template.colors.primary};`"
            >
              mdi-alert
            </v-icon>
          </v-avatar>
        </v-col>
        <v-col align-self="center" class="pa-0">
          <b>{{ title }}</b>
          <br />
          {{ text }}
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="value = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'notification-handler',
  data() {
    return {
      value: false,
      title: 'FEHLER',
      text: 'Dies ist ein Test!',
      timeout: '6000',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
}
</script>
